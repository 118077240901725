import '/src/css/job/header.css'
import '/src/css/job/style.css'
import '/src/css/gerendenglu/login.css'
import '/src/css/gerendenglu/valocde.css'
import '/src/css/yingjiesheng/layer.css'

import '/src/js/gerendenglu/valcode.js'


/*短信-密码登录*/
window.asecBoard = function (n) {
    for ( let i = 1; i < 3; i++) {
        eval("document.getElementById('al0" + i + "').className='a102'");
        eval("document.getElementById('abx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('al0" + n + "').className='a101'");
    eval("document.getElementById('abx0" + n + "').style.display='block'");
}
var valMobile = /^[1][3-9][0-9]{9}$/;

    //密码登录
    
    $("#btnPWDLogin").on("click", function () {
        var Username = $("#pwdUsername").val();
        var Password = $("#pwdPassword").val();
        var PrevUrl = $("#hidPrevUrl").val();
        var AutoLogin = 1;
        if (PrevUrl == '') {
            PrevUrl = '/person/';
        }

        if (Username.length == 0) {
            layer.msg("请输入账号");
            return false;
        }
        else if (Password.length == 0) {
            layer.msg("请输入密码");
            return false;
        }
        else {
            $.ajax({
                url: "",
                type: "",
                data: { Username: Username, Password: Password, AutoLogin: AutoLogin },
                beforeSend: function () {
                    $("#btnPWDLogin").html("正在登录").attr("disabled", "disabled");
                },
                dataType: "json",
                success: function (result) {
                    if (result[0].Flag) {
                        layer.msg("登录成功,正在跳转...");
                        location.href = PrevUrl;
                    }
                    else {
                        $("#btnPWDLogin").html("登 录").removeAttr("disabled");
                        layer.msg(result[0].Msg );
                    }
                },
                complete: function () {
                    $("#btnPWDLogin").html("登 录").removeAttr("disabled");
                },
                error: function () {
                    $("#btnPWDLogin").html("登 录").removeAttr("disabled");
                    layer.msg("操作过程出错！" );
                }
            })
        }
    })


    //短信登录
    $("#btnSMSLogin").on("click", function () {
        var Mobile = $("#smsMobile").val();
        var SMSCode = $("#smsSMSCode").val();
        var PrevUrl = $("#hidPrevUrl").val();
        var AutoLogin = 1;
        if (PrevUrl == '') {
            PrevUrl = '/person/';
        } 
        if (Mobile.length == 0) {
            layer.msg("请输入手机号", JSON.stringify(data.field));
            return false;
        }
        else if (!valMobile.test(Mobile)) {
            layer.msg("手机号格式不正确");
            return false;
        }
        else if (SMSCode.length == 0) {
            layer.msg("请输入验证码");
            return false;
        }
        else {
            $.ajax({
                url: "",
                type: "",
                data: { Mobile: Mobile, SMSCode: SMSCode, AutoLogin: AutoLogin },
                beforeSend: function () {
                    $("#btnSMSLogin").html("正在登录").attr("disabled", "disabled");
                },
                dataType: "json",
                success: function (result) {
                    if (result[0].Flag) {
                        layer.msg("登录成功,正在跳转...");
                        location.href = PrevUrl;
                    }
                    else {
                        $("#btnSMSLogin").html("登 录").removeAttr("disabled");
                        layer.alert(result[0].Msg);
                    }
                },
                complete: function () {
                    $("#btnSMSLogin").html("登 录").removeAttr("disabled");
                },
                error: function () {
                    $("#btnSMSLogin").html("登 录").removeAttr("disabled");
                    layer.msg("操作过程出错！");
                }
            })
        }
    })

    //获取验证码
    $("#btnSend").on("click", function () {
        var Mobile = $("#smsMobile").val();

        if (Mobile.length == 0) {
            layer.msg("请输入手机号码");
        }
        else if (!valMobile.test(Mobile)) {
            layer.msg("手机号格式不正确");
        }
        else {
            $.ajax({
                url: '',
                data: { action: "mobilelogin", value: Mobile },
                type: "",
                beforeSend: function () {
                    $("#btnSend").attr("disabled", true);
                },
                success: function (msg) {
                    switch (msg) {
                        case "true":
                            //弹出验证框
                            layer.open({
                                type: 1,
                                title: false,
                                closeBtn: true,
                                area: '302px',
                                shadeClose: true,
                                content: $('#ValidateDivs')
                            });
                            //滑动验证
                            $("#ValidateDiv").slide("300*200", "sendSMScode");
                            break;
                        case "num":
                            layer.msg("你今日已接收5条信息");
                            break;
                        case "false":
                            layer.msg("该手机号不存在");
                            break;
                    }
                },
                complete: function () {
                    $("#btnSend").attr("disabled", false);
                }
            })
        }
    })

    //请求验证码
    window.sendSMScode = function (token) {
        var mobile = $("#smsMobile").val();
        layer.closeAll();

        if (token) {
            $.ajax({
                type: "POST",
                dataType: "text",
                url: '',
                data: { action: "sendsmscode", value: mobile, smstype: "1", token: token },
                success: function (msg) {
                    switch (msg) {
                        case "true":
                            outtime(); //启动计时
                            break;
                        case "num":
                            layer.msg("你今日已接收5条信息");
                            break;
                        case "black":
                            layer.msg("发送权限屏蔽中");
                            break;
                        case "flag":
                            layer.msg("短信平台屏蔽中");
                            break;
                        case "lost":
                            layer.msg("发送失败");
                            break;
                        case "token":
                            layer.msg("验证失败");
                            break;
                    }
                }
            });
        }
        else {
            layer.msg("验证失败");
        }
    }

    var wait = 60; //计时器
    window.outtime = function () {
        if (wait == 0) {
            $("#btnSend").attr("disabled", false);
            $("#btnSend").val("获取验证码");
            wait = 60;
        } else {
            $("#btnSend").attr("disabled", true);
            $("#btnSend").val('' + wait + "S");
            wait--;
            setTimeout(function () {
                outtime()
            },
           1000)
        }
    }
